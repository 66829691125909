<template>
  <ul>
    <li
      class="emd-dropdown__content__item"
      v-if="isEmployee">
      <a
        :href="$sanitize(urlBackoffice)"
        class="emd-text _type_bold _color_default">{{$t('backoffice')}}</a>
    </li>
    <li class="emd-dropdown__content__item _color_danger">
      <a
        href="javascript:void(0);"
        @click="logout">
        <span class="emd-text _type_bold _color_danger">{{ $t('logout') }}</span></a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'UserListLinks',
  props: {
    isEmployee: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    urlBackoffice () {
      return `${process.env.VUE_APP_BACKOFFICE_URL}`
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('DELETE_AUTHORIZATION', true)
    }
  }
}
</script>

<i18n>
{
  "pt-BR": {
    "logout": "Sair",
    "backoffice": "Acessar Backoffice"
  },
  "en-US": {
    "logout": "Logout",
    "backoffice": "Access Backoffice"
  }
}
</i18n>
